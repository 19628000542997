
import PanelSideMenu from "@/components/PanelSideMenu.vue";
import TheHeader from "@/components/TheHeader.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: { TheHeader, PanelSideMenu },
  setup() {
    let openMenu = ref(false);

    return { openMenu };
  },
});
