<template>
  <the-header :is-panel="true" @toggle-menu="openMenu = !openMenu" />

  <div
    :class="[
      { '-translate-x-full': !openMenu },
      'fixed md:w-56 h-full top-12 left-0 z-50 transform md:transform-none transition duration-500',
    ]"
  >
    <panel-side-menu @close="openMenu = false" />
  </div>

  <div class="relative md:ml-56 pt-12 font-barlow bg-bg-gray h-full">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import PanelSideMenu from "@/components/PanelSideMenu.vue";
import TheHeader from "@/components/TheHeader.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: { TheHeader, PanelSideMenu },
  setup() {
    let openMenu = ref(false);

    return { openMenu };
  },
});
</script>
