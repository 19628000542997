<template>
  <div
    class="
      h-full
      pl-2
      pr-4
      pt-8
      pb-6
      bg-bg-gray
      border-r border-bg-gray-dark
      relative
      font-barlow
    "
  >
    <div
      class="
        absolute
        md:hidden
        right-2
        top-1
        hover:text-main-blue
        cursor-pointer
        transition
        duration-300
      "
      @click="$emit('close')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </div>

    <div v-for="item in HOME_ITEMS" :key="item.id">
      <router-link custom v-slot="{ href, navigate, isActive }" :to="{ name: item.id }">
        <div
          :class="[
            {
              'border-secondary-blue bg-bg-gray-dark': isActive,
            },
            { 'filter grayscale': !isActive },
            'mb-1 pr-4 py-2 border-l-4 border-transparent flex justify-center items-center gap-x-2 hover:bg-bg-gray-dark rounded cursor-pointer transition duration-100',
          ]"
          :active="isActive"
          :href="href"
          @click="navigate"
        >
          <img :src="getImg(item.icon)" class="h-8 w-8" />
          <p>{{ item.title }}</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import homeItems from "@/store/homeItems.json";

export default defineComponent({
  emits: ["close"],
  setup() {
    const HOME_ITEMS = homeItems;
    const getImg = (icon: string) => {
      return require("@/assets/icons/home/" + icon);
    };

    return { HOME_ITEMS, getImg };
  },
});
</script>
