
import { defineComponent } from "vue";
import homeItems from "@/store/homeItems.json";

export default defineComponent({
  emits: ["close"],
  setup() {
    const HOME_ITEMS = homeItems;
    const getImg = (icon: string) => {
      return require("@/assets/icons/home/" + icon);
    };

    return { HOME_ITEMS, getImg };
  },
});
